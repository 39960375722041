// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c473bfacad/0/cache/css-loader-npm-6.8.1-30d84b4cf1-7c1784247b.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c473bfacad/0/cache/css-loader-npm-6.8.1-30d84b4cf1-7c1784247b.zip/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../.yarn/__virtual__/css-loader-virtual-c473bfacad/0/cache/css-loader-npm-6.8.1-30d84b4cf1-7c1784247b.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../.yarn/__virtual__/postcss-loader-virtual-1b0dd6188b/0/cache/postcss-loader-npm-7.3.3-df90db4d0c-c724044d6a.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../styles/typography.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatLegend--StatLegend--AEcS9 {
  position: relative;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.StatLegend--StatLegendItem--vpFW6 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 1em 0 0;
  border-bottom: 2px solid;
}

.StatLegend--StatLegendTitle--GpUwT {
  letter-spacing: -.01em;
  white-space: nowrap;
  line-height: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/stat/StatLegend.css"],"names":[],"mappings":"AAAA;EAEE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".StatLegend {\n  composes: smallSize from '../../../styles/typography.css';\n  position: relative;\n  list-style: none;\n  display: flex;\n  margin: 0;\n  padding: 0;\n}\n\n.StatLegendItem {\n  display: flex;\n  flex-wrap: nowrap;\n  align-items: center;\n  margin: 0 1em 0 0;\n  border-bottom: 2px solid;\n}\n\n.StatLegendTitle {\n  letter-spacing: -.01em;\n  white-space: nowrap;\n  line-height: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StatLegend": `StatLegend--StatLegend--AEcS9 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"StatLegendItem": `StatLegend--StatLegendItem--vpFW6`,
	"StatLegendTitle": `StatLegend--StatLegendTitle--GpUwT`
};
export default ___CSS_LOADER_EXPORT___;
