
      import API from "!../../../../.yarn/__virtual__/style-loader-virtual-a6c3e833ca/0/cache/style-loader-npm-3.3.3-2e8bbeeac4-f59c953f56.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../.yarn/__virtual__/style-loader-virtual-a6c3e833ca/0/cache/style-loader-npm-3.3.3-2e8bbeeac4-f59c953f56.zip/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../.yarn/__virtual__/style-loader-virtual-a6c3e833ca/0/cache/style-loader-npm-3.3.3-2e8bbeeac4-f59c953f56.zip/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../.yarn/__virtual__/style-loader-virtual-a6c3e833ca/0/cache/style-loader-npm-3.3.3-2e8bbeeac4-f59c953f56.zip/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../.yarn/__virtual__/style-loader-virtual-a6c3e833ca/0/cache/style-loader-npm-3.3.3-2e8bbeeac4-f59c953f56.zip/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../.yarn/__virtual__/style-loader-virtual-a6c3e833ca/0/cache/style-loader-npm-3.3.3-2e8bbeeac4-f59c953f56.zip/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../.yarn/__virtual__/css-loader-virtual-c473bfacad/0/cache/css-loader-npm-6.8.1-30d84b4cf1-7c1784247b.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../.yarn/__virtual__/postcss-loader-virtual-1b0dd6188b/0/cache/postcss-loader-npm-7.3.3-df90db4d0c-c724044d6a.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./SingleLineCollapsibleContainerLabel.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../.yarn/__virtual__/css-loader-virtual-c473bfacad/0/cache/css-loader-npm-6.8.1-30d84b4cf1-7c1784247b.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../.yarn/__virtual__/postcss-loader-virtual-1b0dd6188b/0/cache/postcss-loader-npm-7.3.3-df90db4d0c-c724044d6a.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!./SingleLineCollapsibleContainerLabel.css";
       export default content && content.locals ? content.locals : undefined;
