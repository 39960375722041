// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c473bfacad/0/cache/css-loader-npm-6.8.1-30d84b4cf1-7c1784247b.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c473bfacad/0/cache/css-loader-npm-6.8.1-30d84b4cf1-7c1784247b.zip/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../.yarn/__virtual__/css-loader-virtual-c473bfacad/0/cache/css-loader-npm-6.8.1-30d84b4cf1-7c1784247b.zip/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../.yarn/__virtual__/postcss-loader-virtual-1b0dd6188b/0/cache/postcss-loader-npm-7.3.3-df90db4d0c-c724044d6a.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../styles/typography.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatTooltip--row--tFzNn {
  line-height: 20px;
  max-width: 180px;
  min-width: 160px;
}

.StatTooltip--message--MOr7T {
  color: var(--stat--default);
}

.StatTooltip--message--MOr7T p {
  margin: 0;
}

.StatTooltip--message--MOr7T a {
  pointer-events: all;
}

.StatTooltip--divider--gqRkM {
  height: 2px;
  margin: 5px -10px;
  background-color: var(--stat--default);
}
`, "",{"version":3,"sources":["webpack://./src/components/common/tooltips/StatTooltip.css"],"names":[],"mappings":"AAAA;EAEE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EAEE,2BAA2B;AAC7B;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,sCAAsC;AACxC","sourcesContent":[".row {\n  composes: smallSize from '../../../styles/typography.css';\n  line-height: 20px;\n  max-width: 180px;\n  min-width: 160px;\n}\n\n.message {\n  composes: row;\n  color: var(--stat--default);\n}\n\n.message p {\n  margin: 0;\n}\n\n.message a {\n  pointer-events: all;\n}\n\n.divider {\n  height: 2px;\n  margin: 5px -10px;\n  background-color: var(--stat--default);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `StatTooltip--row--tFzNn ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"message": `StatTooltip--message--MOr7T StatTooltip--row--tFzNn ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"divider": `StatTooltip--divider--gqRkM`
};
export default ___CSS_LOADER_EXPORT___;
